import React from "react";
import Layout from "../other/layout";
import AProposDeNebs from "../components/AC-StaticPages/AProposDeNebs";
import SEO from "../other/seo";

export default function aProposDeNebs() {
  return (
    <Layout>
      <SEO title="À propos de NEBS" />

      <AProposDeNebs />
    </Layout>
  );
}
