import React from "react";
import styles from "./styles.module.css";

export default function AProposDeNebs() {
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <h1>À propos de NEBS</h1>
        <p>
          <span>Produits d'Affaires Deluxe</span> Innovation. Travail acharné.
          Honnêteté. Entrepreneuriat.
        </p>
        <p>
          Depuis plus de 30 ans, les entreprises canadiennes font confiance à
          Deluxe pour son expertise en conception et ses produits de spécialité.
        </p>
        <p>
          Située dans la région de Midland en Ontario, Deluxe a aidé plus de 200
          000 entreprises canadiennes à démarrer, gérer et dé velopper leurs
          affaires en leur offrant une gamme étendue de produits et de services
          innovateurs.
        </p>
        <p>Voici quelques-uns de nos nouveaux services :</p>
        <h2>Conception</h2>
        <p>
          Donnez-vous une longueur d'avance sur vos concurrents avec votre
          propre Programme de positionnement, de stratégie de marque et de logo.
          Deluxe vous aidera à profiter de toutes les occasions de faire
          connaître votre marque en mettant votre logo sur vos chèques, vos
          formulaires, votre papeterie, vos imprimés promotionnels, vos
          étiquettes et beaucoup d'autres articles imprimés.
        </p>
        <h2>Promotion de votre entreprise</h2>
        <p>
          Mettez votre marque partout! Nous pouvons vous aider à diffuser votre
          marque et augmenter vos ventes avec une vaste gamme d'imprimés
          promotionnels : cartes postales, feuillets publicitaires, dépliants et
          plus. Nous offrons un assortiment complet de cadeaux publicitaires et
          de vêtements qui sauront mettre en valeur votre logo, c'est à dire
          votre marque.
        </p>
        <h2>Services d'enregistrement d'entreprise</h2>
        <p>
          Deluxe offre maintenant aux entrepreneurs et propriétaires de petits
          commerces canadiens des services d'information commerciale et
          d'enregistrement, incluant la constitution d'une société en personne
          morale, l'enregistrement d'une entreprise individuelle ou d'une
          société en nom collectif, des rapports de dénominations sociales, le
          dépôt d'une marque de commerce et la mise à jour d'entreprise.
        </p>
        <h2>Services Internet</h2>
        <p>
          Deluxe offre dorénavant des services qui vous permettront de mettre
          votre entreprise en ligne de façon efficace et abordable : conception
          professionnelle de sites Internet, noms de domaine, hébergement
          Internet et courrier électronique. Choisissez parmi des modèles de
          sites d'utilisation facile, ou faites créer votre site par des
          experts. Nous avons des forfaits de conception et d'hébergement de
          sites Internet pour chaque budget.
        </p>
        <p>
          Toutes les entreprises cherchent des moyens de réussir. Les solutions
          Deluxe de fidélisation et de diffusion de marque augmentent votre
          rentabilité et votre avantage concurrentiel.
        </p>
        <p>
          Deluxe offre une gamme complète de produits et de services pour
          développer vos affaires : impression, services Internet, traitement de
          la paie et matériel promotionnel.
        </p>
      </div>
    </div>
  );
}
